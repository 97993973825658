export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"msapplication-TileColor","content":"#00aba9"},{"name":"theme-color","content":"#ffffff"},{"property":"og:type","content":"website"},{"property":"og:image","content":"/ogimage.webp"},{"property":"og:image:width","content":"1200"},{"property":"og:image:height","content":"630"},{"property":"og:image:type","content":"webp"},{"property":"og:locale","content":"de_CH"},{"property":"og:site_name","content":"Ruba"}],"link":[{"rel":"manifest","href":"/site.webmanifest"},{"rel":"mask-icon","href":"/safari-pinned-tab.svg","color":"#5bbad5"}],"style":[],"script":[{"src":"https://www.googletagmanager.com/gtag/js?id=G-29HXE4PLFH","async":true},{"innerHTML":"window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-29HXE4PLFH'); ","type":"text/javascript"}],"noscript":[],"htmlAttrs":{"lang":"de-CH","dir":"ltr"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'